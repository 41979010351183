<template>
    <div class="comparison">
      <div class="main-content">
        <div class="main-center">
          <!--搜索-->
          <div class="search-dom">
            <el-card class="box-card" shadow="never">
              <el-divider content-position="left">
                <img src="../../assets/siteImg/title.png" alt="" style="vertical-align: middle">
                <span style="vertical-align: middle">查询文本或查询文件</span>
              </el-divider>
              <el-row type="flex" justify="space-around">
                <el-col style="margin-top:24px" :span="21">
                  <el-form ref="ValidateForm" :model="ValidateForm" label-width="0px" class="demo-ruleForm">
                    <el-row type="flex" justify="space-around">
                      <el-col :span="17">
                        <el-form-item
                          label=""
                          prop="desc"
                          :rules="[{ required: true, message: '序列不能为空'},{ validator: checkdesc, trigger: 'change' }]"
                        >
                          <el-input
                            v-model="ValidateForm.desc"
                            type="textarea"
                            :rows="14"
                            placeholder="请输入序列"
                            autocomplete="off"
                            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
                          />
                        </el-form-item>
                      </el-col>
                      <!--上传-->
                      <el-col :span="5">
                        <el-form-item label="" class="upload-item">
                          <el-upload
                            ref="uploadTxt"
                            :auto-upload="false"
                            :limit="1"
                            :show-file-list="false"
                            :on-change="fileChange"
                            action=""
                            accept=".txt"
                            class="avatar-uploader"
                          >
                            <el-icon class="avatar-uploader-icon"><UploadFilled /></el-icon>
                            <div class="el-upload__text"><em>点击上传</em>一个txt文件</div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex" justify="space-around">
                      <el-col :span="6">
                        <el-form-item>
                          <el-button type="primary" @click="submitForm('ValidateForm')">查询</el-button>
                          <el-button @click="resetForm('ValidateForm')">重置</el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GeneComparison',
    data() {
      this.checkdesc = (rule, value, callback) => { // 正则验证输入的序列格式是否正确
        if (value) {
          const values = value.replace(/\s/g, '').toUpperCase()
          const isletter = /^[ATGC-]*$/.test(values)
          if (!isletter) {
            callback(new Error('文本内容仅由 ATGC 的英文字母和缺失字符 - 组成!'))
          }
          callback()
        }
        callback()
      }
      return {
        ValidateForm: {
          desc: ''
        }
      }
    },
    beforeDestroy() {
      window.sessionStorage.removeItem('obj')
    },
    methods: {
      fileChange(file) {
        this.ValidateForm.desc = ''
        this.contentRead(file)
      },
      // 读取上传文件内容
      contentRead(file) {
        if (file.raw) {
          const that = this
          const reader = new FileReader()// 读取文件内容
          reader.readAsText(file.raw, 'UTF-8') // 防止中文乱码问题，不加reader.onload方法都不会触发
          reader.onload = function(e) {
            const value = e.target.result.replace(/\s/g, '').toUpperCase()
            const isletter = /^[ATGC-]*$/.test(value)
            if (isletter) {
              that.ValidateForm.desc = value // txt文本内容，接下来就可以对其进行校验处理了
              that.queryParam = ''
              // 调查询接口
              // that.queryFunc()
            } else {
              that.$notify({
                message: '上传文件中的文本内容只能由含有 ATGC 的英文字母和缺失字符 - 组成!',
                type: 'warning',
                duration: 2500
              })
            }
            // 清空上传列表
            that.$refs.uploadTxt.clearFiles()
          }
        }
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const para = {
              str: this.ValidateForm.desc.toUpperCase().replace(/\s*/g, ''),
              job: '',
              num: 2
            }
            window.sessionStorage.setItem('obj', JSON.stringify(para))
            const newPage = this.$router.resolve({ name: 'toolsTextRes' })
            window.open(newPage.href, '_blank')
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .comparison {
      height: 100vh;
      background-color: white;
      .main-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-center {
          width: 73.61%;
          .search-dom {
            width: 100%;
            border: 1px solid rgb(184, 184, 184);
            position: relative;
            .el-divider {
              background-color: #134AAA;
            }
            .el-divider__text {
              color: #134AAA;
              padding: 0 10px 0 0px
            }
            .el-divider__text.is-left {
              font-size:16px;
              left: 0px;
            }
            .el-divider__text {
              font-size: 16px;
              font-weight: bold;
              font-style: italic;
            }
  
            // >>>.upload-item {
            //   height: 94%;
            //   .el-form-item__content {
            //     height: 100%;
            //   }
            // }
            .avatar-uploader {
              height: 100%;
              border: 1px dashed #C4CDDE;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .avatar-uploader .el-upload:hover {
              border-color: #409EFF;
            }
            .avatar-uploader-icon {
              font-size: 100px;
              color: #6197FE;
            }
            .el-upload__text {
              em {
                color: #134aaa;
                font-style: normal;
              }
            }
            .avatar {
              width: 178px;
              height: 178px;
              display: block;
            }
            .el-textarea__inner {
              border: 1px solid #C4CDDE;
              border-radius: 5px;
            }
            .el-card {
              background-color: transparent;
              border: none;
            }
           .el-card__body {
              padding: 60px;
            }
            .el-button--default {
              color: #134AAA;
              border-color: #134AAA;
            }
          }
        }
      }
    }

   /deep/ .el-upload {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    outline: 0;
    width: 211px;
    height: 300px;
    }
  </style>
  